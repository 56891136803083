<template>
  <BasicModal
    :title="detail.jobName"
    width="600px"
    :visible="visible"
    okText="运行"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <div class="project-edit-content">
      <BasicForm
        class="project-edit-form"
        ref="formRef1"
        :labelCol="{ span: 6 }"
        :formItemsMap="formItemsMap1"
        v-model:formData="formData1"
      ></BasicForm>
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, defineEmits, reactive, onMounted } from "vue";
import { message } from "ant-design-vue";
import { apiScheduleTask } from "@/api/task.js";
// import { validatePhone } from "@/components/basic/form/validator";

const props = defineProps({
  detail: {
    type: Object,
    default: null,
  },
  visible: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["change", "update:visible", "success"]);
const formRef1 = ref(null);

onMounted(() => {
  initFormItems();
});

// 统计周期
const periodOptions = [
  {
    picker: "date",
    valueFormat: "YYYY-MM-DD HH:mm:ss",
  },
  {
    picker: "date",
    valueFormat: "YYYY-MM-DD",
  },
  {
    picker: "month",
    valueFormat: "YYYY-MM",
  },
  {
    picker: "year",
    valueFormat: "YYYY",
  },
];

const formItemsMap1 = reactive({
  user: {
    label: "所属租户",
    prop: "tenantId",
    type: "input",
    disabled: true,
    span: 18,
  },
  type: {
    label: "统计类型",
    prop: "statisticType",
    type: "input",
    disabled: true,
    span: 18,
  },
  project: {
    label: "项目",
    prop: "bizProjectId",
    type: "select",
    fieldNames: {
      label: "name",
      value: "bizProjectId",
    },
    required: true,
    options: [],
    span: 18,
    requiredMessage: "请选择",
  },
  area2: {
    label: "统计周期",
    prop: "peakTimes",
    type: "rangePicker",
    showTime: props.detail.statisticType == 0 ? true : false,
    valueFormat: periodOptions[props.detail.statisticType].valueFormat,
    picker: periodOptions[props.detail.statisticType].picker,
    span: 18,
    required: true,
  },
});
const formData1 = reactive({
  tenantId: props.detail.tenantName,
  statisticType: props.detail.statisticTypeName,
  bizProjectId: "",
  peakTimes: [],
});

const initFormItems = async () => {
  let { result } = await apiScheduleTask.projectList({
    tenantId: props.detail.tenantId,
  });
  formItemsMap1.project.options = result || [];
};

const confirmLoading = ref(false);
const ok = async () => {
  try {
    await formRef1.value.formRef.validateFields();
    const params = {
      jobId: props.detail.id,
      tenantId: props.detail.tenantId,
      bizProjectId: formData1.bizProjectId,
      startTime: formData1.peakTimes[0],
      endTime: formData1.peakTimes[1],
    };
    await apiScheduleTask.run(params);
    message.success(`任务下发成功！`);
    emit("update:visible", false);
    emit("success");
    confirmLoading.value = false;
  } catch (errorInfo) {
    confirmLoading.value = false;
    console.log("Failed:", errorInfo);
  }
};
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>
