<template>
  <BasicModal
    :title="`${info.jobName}日志`"
    width="980px"
    :closable="true"
    :visible="visible"
    okText="运行"
    :footer="null"
    :confirmLoading="confirmLoading"
    @ok="ok"
    @cancel="cancel"
  >
    <div class="detailwrap">
      <div class="project-list-header">
        <CategorySearch :source="filterSource" @change="search" />
      </div>
      <div>
        <BasicTable
          ref="tableRef"
          v-bind="tableConfig"
          @page-change="pageChange"
        >
        </BasicTable>
      </div>
    </div>
  </BasicModal>
</template>

<script setup>
import { defineProps, ref, reactive, onMounted, defineEmits } from "vue";
import { apiScheduleTask } from "@/api/task.js";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
import dayjs from "dayjs";

const props = defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  info: {
    type: Object,
    default() {
      return {};
    },
  },
});

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initTableData();
});

const filterSource = ref([
  {
    label: "项目名称",
    type: "input",
    bindKey: "projectName",
    placeholder: "请输入",
  },
  {
    label: "时间",
    bindKey: "startTime, endTime",
    showTime: true,
    type: "rangePicker",
  },
  {
    label: "状态",
    type: "select",
    bindKey: "status",
    options: [
      {
        label: "成功",
        value: "0",
      },
      {
        label: "失败",
        value: "1",
      },
    ],
  },
]);
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: '350px',
  },
  columns: [
    {
      title: "日志时间",
      type: "text",
      key: "execTime",
      width: 80,
      formatter(val) {
        return val ? dayjs(val).format("YYYY-MM-DD HH:mm:ss") : "-";
      },
    },
    {
      title: "任务",
      type: "text",
      key: "jobName",
      width: 100,
      ellipsis: true,
    },
    {
      title: "项目",
      type: "text",
      key: "projectNames",
      width: 120,
    },
    {
      title: "执行方式",
      type: "text",
      key: "execTypeName",
      width: 60,
    },
    {
      title: "状态",
      type: "text",
      key: "statusName",
      width: 60,
    },
  ],
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    tenantId: props.info.tenantId,
    jobId: props.info.id,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    const res = await apiScheduleTask.logList(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const emit = defineEmits(["change", "update:visible", "success"]);
const cancel = () => {
  emit("update:visible", false);
};
</script>

<style lang="less" scoped>
.detailwrap {
  min-height: 500px;
}
.project-edit-content {
  gap: 20px;
  /deep/.ant-row {
    justify-content: space-between;
  }
}
.project-edit-form {
  flex: 1;
}
/deep/.form2 {
  .ant-form-item-label {
    width: 89px;
  }
  .ant-form-item-control-input-content {
    section {
      &:first-child {
        margin-top: 0px;
      }
    }
  }
  section {
    display: flex;
    justify-content: space-between;
    margin-top: 16px;
  }
  input {
    width: 320px;
  }
}
.bt-add {
  width: 100%;
  color: #0256ff;
  background-color: #ecf4ff;
  margin-top: 16px;
  font-size: 12px;
}
</style>
