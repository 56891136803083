import http from "../utils/http";

// 应用菜单接口 
export const apiScheduleTask = {
  //任务分页
  pageList(params) {
    return http.request({
      url: "/cemp-job/schedule/list",
      method: "get",
      params,
    });
  },
  //手动运行
  run(data) {
    return http.request({
      url: "/cemp-job/schedule/manual-run",
      method: "put",
      data,
    });
  },
  //日志分页
  logList(params){
    return http.request({
      url: "/cemp-job/schedule-logger/list",
      method: "get",
      params,
    });
  },
  //项目
  projectList(params){
    return http.request({
      url: "/cemp-bms/project/schedule/list",
      method: "get",
      params,
    });
  },
};
