<template>
  <div class="project-list">
    <div class="project-list-header">
      <CategorySearch :source="filterSource" @change="search" />
    </div>
    <BasicTable ref="tableRef" v-bind="tableConfig" @page-change="pageChange">
      <template #slotStatus="{ row }">
        <a-tag :color="row.lastStatus == 0 ? '#87d068' : '#f50'">{{
          row.lastStatusName
        }}</a-tag>
      </template>
    </BasicTable>
  </div>
  <taskModal
    v-if="runModalVisible"
    v-model:visible="runModalVisible"
    :detail="currentRow"
    @success="editSuccess"
  ></taskModal>
  <logModal
    v-if="detailModalVisible"
    :info="currentRow"
    v-model:visible="detailModalVisible"
  ></logModal>
</template>
<script setup>
import { ref, reactive, onMounted } from "vue";
import CategorySearch from "@/components/business/CategorySearch/categorySearch.vue";
import { BasicTable } from "@/components/basic/table";
// import { Modal, message } from "ant-design-vue";
import { apiScheduleTask } from "@/api/task.js";
import { apiRole } from "@/api/authority.js";
import taskModal from "./components/taskModal";
import logModal from "./components/logModal";
import dayjs from "dayjs";
import _ from "lodash";

onMounted(() => {
  tableRef.value.paginationProps.pageSize = 20;
  initFilterOption();
  initTableData();
});

const filterSource = ref([
  {
    label: "租户",
    type: "select",
    bindKey: "tenantId",
    options: [],
    fieldNames: {
      label: "tenantName",
      value: "tenantId",
    },
  },
  {
    label: "任务名称",
    type: "input",
    bindKey: "jobName",
    placeholder: "请输入",
  },
  {
    label: "运行状态",
    type: "select",
    bindKey: "lastStatus",
    options: [
      {
        label: "成功",
        value: "0",
      },
      {
        label: "失败",
        value: "1",
      },
    ],
  },
  {
    label: "统计类型",
    type: "select",
    bindKey: "statisticType",
    options: [
      {
        label: "年",
        value: "3",
      },
      {
        label: "月",
        value: "2",
      },
      {
        label: "日",
        value: "1",
      },
      {
        label: "时",
        value: "0",
      },
    ],
  },
]);

const initFilterOption = async () => {
  let { result } = await apiRole.tenants();
  const _list = _.cloneDeep(filterSource.value);
  _list[0].options = result || [];
  filterSource.value = _list;
};
let searchData = {};
const search = (data) => {
  searchData = data;
  tableRef.value.paginationProps.current = 1;
  initTableData();
};

const tableRef = ref();
const tableConfig = reactive({
  showIndex: false,
  showSelect: false,
  pagination: true,
  isPageBottom: true,
  remote: false,
  loading: false,
  scroll: {
    scrollToFirstRowOnChange: true,
    y: "calc(100vh - 280px)",
  },
  columns: [
    {
      title: "ID",
      type: "text",
      key: "id",
      width: 80,
    },
    {
      title: "租户名称",
      type: "text",
      key: "tenantName",
      width: 100,
      ellipsis: true,
    },
    {
      title: "任务名称",
      type: "text",
      key: "jobName",
      width: 120,
    },
    {
      title: "统计类型",
      type: "text",
      key: "statisticTypeName",
      width: 60,
    },
    {
      title: "上次运行状态",
      type: "customize",
      key: "lastStatusName",
      width: 60,
      slotName: "slotStatus",
    },
    {
      title: "上次运行时间",
      type: "text",
      key: "lastTime",
      width: 140,
      formatter(val) {
        return val ? dayjs(val).format("YYYY-MM-DD HH:mm:ss") : "-";
      },
    },
  ],
  action: {
    title: "操作",
    width: 50,
    fixed: "right",
    items: [
      {
        type: "icon",
        icon: "ToolOutlined",
        iconTip: "运行",
        onClick: (row) => {
          handleRun(row);
        },
      },
      {
        icon: "FileSearchOutlined",
        iconTip: "日志",
        type: "icon",
        onClick: (row) => {
          handleHistory(row);
        },
      },
    ],
  },
});
const initTableData = async () => {
  const { current, pageSize } = tableRef.value.paginationProps;
  const params = {
    pageNo: current,
    pageSize,
    ...searchData,
  };
  tableConfig.loading = true;
  try {
    tableRef.value.dataSourceRef = [];
    const res = await apiScheduleTask.pageList(params);
    const { records, total } = res?.result ?? {};
    tableConfig.loading = false;
    tableRef.value.dataSourceRef = records;
    tableRef.value.paginationProps.total = total;
  } catch (error) {
    tableConfig.loading = false;
    console.log(error);
  }
};
const pageChange = (pageIndex) => {
  tableRef.value.paginationProps.current = pageIndex;
  initTableData();
};

const runModalVisible = ref(false);
const currentRow = ref(null);
const handleRun = (row) => {
  runModalVisible.value = true;
  currentRow.value = row;
};
const editSuccess = () => {
  initTableData();
};

const detailModalVisible = ref(false);

const handleHistory = (row) => {
  detailModalVisible.value = true;
  currentRow.value = row;
};
</script>
<style lang="less" scoped>
.project-list {
  height: 100%;
  padding: 16px 20px;
  background: #fff;
  &-header {
    display: flex;
    justify-content: space-between;
  }
  &-table-button {
    line-height: 1;
    height: auto;
  }
}
</style>
